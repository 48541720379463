a, p{
  color: #232323
}
p{
  color: #a7a7a7
}
.ant-notification{
  /* z-index: 9999 !important; */
  top: 60px !important;
}
.auth{
  background: url(/images/background/background.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.authHeader{
  background-color: white;
  height: 54px;
}

.authHeader > h2{
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  padding-left: 20px;
}

.auth .ant-tabs {
    width: 400px;
}

.auth .ant-tabs-nav {
    display: flex;
}

.auth .ant-tabs-nav .ant-tabs-tab {
    flex-grow: 1;
    margin-right: 0px;
    width: 100%;
    text-align: center;
}

.auth .ant-input-prefix i {
    color: rgba(0,0,0,.25)
}

.auth .mainAuthBox{
  background-color: #fff;
  min-height: 580px;
  max-height: 90%;
  overflow: auto;
  top:40px;
  box-shadow: 0 2px 12px 0 rgba(163,163,163,0.5);
  border-radius: 6px;
  padding: 0px 25px;
  opacity: 0.9;
  margin: 0;
 position: absolute;
 top: 50%;
 left: 50%;
 -ms-transform: translate(-50%, -50%);
 transform: translate(-50%, -50%);
  /* max-width: 600px; */
}
.auth .mainAuthBox form{
  padding: 30px 20px;
  text-align: center;
}

.auth .authLinkMain{
  font-weight: 400;
  text-align: center;
  margin-top: 50px;
}

.auth .authLinkMain .authLink{
  font-size: 30px;
  font-weight: 300;
  color: #4D77F8;
}

.auth .authLinkMain + .ant-form .ant-btn-primary{
  margin: auto
}

.authLinkMain h1 {
  color: #4D77F8;
  font-size: 30px;
}

.auth .mainAuthBox input{
  height: 45px;
  border: 1px solid #6B6B6B;
  border-radius: 6px;
}

.auth .mainAuthBox input:not(output):-moz-ui-invalid {
  box-shadow: none;
}

.form-button.ant-btn-primary-global {
  max-width: 220px;
  margin: 0 auto 20px;
}

.authLinkUnderline{
  text-decoration: underline;
}
.temrsconditiontext{
  overflow: auto;
}
.authtermbutton{
  display: inline !important;
  margin: 20px 10px 30px 10px !important;
}
.mainAuthBox .authtermbutton{
  max-width: 220px;
}
.authtermsconditionText{
  max-height: 400px;
  overflow: auto;
  border: 1px solid #6B6B6B;
  padding: 25px;
  color: #232323;
  position: relative;
}

.register-dropdown .ant-select-selection {
  border: 1px solid #6B6B6B;
}

.authtermsconditionText + div > .ant-btn-default{
  border: 1px solid #DF0E89;
  color: #DF0E89;
}
.authEmailVerifyText{
  margin-top: 50px;
  max-height: 400px;
  padding: 0px 20px 0px 20px;
  color:gray;
}
.authPaperPlan{
  margin-top: 100px;
}
.mainAuthBox .ant-btn-default{
  color: #a7a7a7;
}
.signup-role{
  width: 38% !important;
  display: inline !important;
  margin: 25px 20px 20px;
  border: 0;
  box-shadow: none;
  font-family: NunitoSans;
  font-weight: 500;
  font-size: 18px;
}

.ant-select-selection--single {
  height: 45px;
  padding-top: 7px;
}

/* Chrome, Safari, Edge, Opera */
.auth .mainAuthBox input[type=number]::-webkit-outer-spin-button,
.auth .mainAuthBox input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.auth .mainAuthBox input[type=number] {
  -moz-appearance:textfield;
}

.authLinkMain + div .container > div > div{
  height: auto !important;
}

.authLinkMain + div canvas{
  width: 100% !important;
  height: auto !important;
}

.ant-btn-primary:hover .signup-role{
  color: #4D77F8 !important;
}

.ant-btn-primary:hover {
  color: #4D77F8 !important;
}

.howRegister{
  color: #a7a7a7;
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
}

.auth-override-height-mobile {
  padding-top:50px;
}


@media screen
  and (max-device-width: 768px) {
  .auth-override-height-mobile {
    padding-top:80px;
  }
}


@media only screen and (min-width: 767px) {
  .authInlineLeft{
    padding-right: 7px;
  }

  .authInlineRight{
    padding-left: 7px;
  }
}

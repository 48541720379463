.header .ant-menu-horizontal {
  line-height: 65px;
}

.rf-editor, .DraftEditor-root {
  font-family: 'NunitoSans' !important;
  line-height: 25px;
}
.rf-editor div[class*="RichTextEditor__paragraph"], .desc-editor div[class*="RichTextEditor__paragraph"], .profileDesc > p, .job-description-content > p {
  margin: 0px;
}
.logo{
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  padding-left: 3px;
  display: inline-block;
  position: relative;
  left: 5px;
  background-image: url(/images/Logo.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 40px;
  width: 180px;
  margin-top: 8px;
}
.logo-mobile{
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  display: inline-block;
  position: relative;
  background-image: url('/images/Logo.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 40px;
  width: 118px;
  margin-top: 8px;
  margin-left: 10px;
}
.ant-menu-submenu-title{
  padding: 4px 20px;
}
.headerSearchBar{
  background-color: white;
  height: 53px;
  padding-left: 10px;
}
.headerSearchBar .searchSuggestions{
  background-color: white;
  height: 450px;
  top:-10px;
  position: relative;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.17);
  border-radius: 0px 0px 6px 6px;
}
.headerSearchBar .suggestionTitle, .searchSuggestions .suggestionTitle {
  font-size: 16px;
  font-weight: 600;
  color: #4D77F3;
  line-height: 20px;
  text-transform: capitalize;
}
.suggestionParent p {
  color: black;
}
.headerSearchBar .suggestion{
  border-right: 1px solid #a7a7a7;
  height: 100%;
  padding: 15px 15px;
  overflow: auto;
}
.headerSearchBar .suggestionParent div{
  line-height: 18px;
  margin: 15px 0px;
  font-size: 14px;
  color: #646464;
  cursor: pointer;
}
.headerSearchBar .searchSelect {
  bottom: 6px;
}
.headerSearchBar .ant-input-search-icon {
    color: #4D77F3;
    font-size: 26px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  margin-top: 0px !important;
}
.clearBtn {
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
}
.headerSearchBar .searchSelect .ant-select-selection{
  background-color: #4D77F3;
  /* width: 177px; */
  border: 0px;
  color: white;
  border-radius: 6px;
}
.searchDropDown {
  background-color: #7d7d7d;
  width: 200px !important;
}
.searchDropDown li.ant-select-dropdown-menu-item-selected {
  background-color: #4D77F3 !important;
}
.searchDropDown li.ant-select-dropdown-menu-item-selected:hover {
  color: white !important;
}
.searchDropDown li {
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
}
.searchDropDown li:hover{
  color: black;
}
.headerSearchBar .searchSelect .ant-select-arrow .ant-select-arrow-icon {
  color: white;
}
.headerSearchBar .searchSelect .ant-select-selection .ant-select-selection-selected-value{
  line-height: 35px;
}
.headerSearchBar .searchInput{
  /* width: calc(100% - 140px); */
  height: 53px;
  top: -6px;
}
.headerSearchBar .searchInput .ant-input-suffix {
  margin-top: 4px;
}
.headerSearchBar input{
  margin: 5px !important;
  background-color: white;
  border: 0px;
}
.headerSearchBar .tabDesigText, .tagDesignBottomMobile .tabDesigText {
  display: inline-block !important;
  line-height: 21px !important;
  font-size: 14px;
  margin-top: 1px;
  overflow: hidden;
  max-width: 95%;
}
.headerSearchBar .tagDesign > i, .tagDesignBottomMobile > i {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  top: -5px;
  position: relative;
}
.ant-tag tagDesign i {
  margin-top: 5px;
}
.headerSearchBar .tagDesign, .tagDesignBottomMobile {
  height: 28px !important;
  border-radius: 6px !important;
  background-color: #4D77F3 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 1px 10px !important;
  border: none;
  font-family: 'NunitoSans' !important;
  margin: 5px 5px;;
  max-width: 100%;
}
.headerSearchBar .searchModal{
  display: inline-block;
  /* width: calc(100% - 177px); */
}
.mainModalCalling{
  text-align: center;
}
.mainModalCalling img {
  max-width: 70px;
  border-radius: 50%;
  margin-top: 14px;
}
.mainModalCalling p {
  padding-top: 14px;
}
.ant-scroll-number-only .current{
  color:white;
}
.headerMessageIcon{
  font-size: 25px;
  left: calc(100% - 20px);
  position: relative;
}
.headerMessageIcon:hover svg{
  filter: invert(59%) sepia(69%) saturate(6268%) hue-rotate(213deg) brightness(101%) contrast(95%);
}
.headerMessageIcon .ant-scroll-number {
  background-color: #DF0E89;
  z-index: 10001;
}

.headerTrackJobIcon{
  position: relative;
  top: -4px;
  height: 25px;
  left: calc(100% - 95px);
}
.headerTrackJobIconImg:hover{
  filter: invert(59%) sepia(69%) saturate(6268%) hue-rotate(213deg) brightness(101%) contrast(95%);
}
.headerTrainingCenter{
  position: relative;
  top: -7px;
  height: 25px;
  left: calc(100% - 175px);
}

/* div.ant-notification-topRight{
  z-index: 1001 !important;
  top: 60px !important;
} */
/* .main-ant-header img.headerTrainingCenter{
  filter: invert(27%) sepia(89%) saturate(5135%) hue-rotate(310deg) brightness(85%) contrast(107%);
} */
.headerAvatar{
  height: 32px;
  width: 32px;
  /* background-color: #B7B7B7; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  border-radius: 15px;
}
.headerUserData .ant-menu-submenu-title{
  padding: 11px 17px;
}
.headerSearchBar .addedTagsParent{
  line-height: 20px;
  height: 340px;
  overflow: auto;
}
.headerSearchBar .headerSearchButton{
  width: calc(100% - 30px);
  position: absolute;
  bottom: 10px;
}
.ant-dropdown > .searchOverlay{
  top: -4px;
  border-radius: 0px;
  padding: 0px 10px;
}
.headerSearchOverlay{
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 11; /* Specify a stack order in case you're using a different order for other elements */
}
.headerSearchOverlayDisplay{
  display: block;
}
.ant-modal-title{
  font-weight: 900 !important;
}
.ant-tooltip{
  z-index: 9999;
}
.adminHeader {
  background: white;
  z-index: 2;
  position: fixed;
  width: 100%;
  box-shadow: 3px 5px 20px 0px #8888886b;
  height: 60px;
}
.headerCover {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: white;
  z-index: 2;
}
.adminLogout {
  font-size: 30px;
  float: right;
  margin-right: 20px;
  margin-top: 14px;
  color: #4d77f8;
}
.adminTitle {
  text-align:center;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #4d77f8;
  height: 30px;
  margin-top: 22px;
  line-height: 1.7;
  margin-bottom: 20px;
}
.adminLogo {
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  padding-left: 10px;
  display: inline-block;
  position: relative;
  left: 43px;
  background-image: url(/images/Logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 40px;
  width: 160px;
  margin-top: 9px;
}
.headerUserData{
  background-color: transparent;
  border-bottom: 0px;
}

.signInHeader,
.signInHeader:hover,
.signInHeader:active {
  cursor: default;
  border: none !important;
  margin-top: 2px !important;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: normal;
  src: url('/font_avenir/AvenirLTStd-Book.ttf');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: bold;
  src: url('/font_avenir/AvenirLTStd-Heavy.otf');
}

@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: normal;
  src: url('/font_nunito/NunitoSans-Regular.ttf');
}
@font-face {
  font-family: 'NunitoSans';
  font-style: normal;
  font-weight: bold;
  src: url('/font_nunito/NunitoSans-Bold.ttf');
}
body {
  font-family: 'NunitoSans' !important;
  color: #424242;
}
@media screen and (max-width: 768px) {
  .searchSuggestions {
    height: 100%;
  }
}
@media screen and (min-width: 992px) {
  /*View for desktop*/
  .extra-row-mobile {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .signInHeader button{
  }
}

/*
 * Buttons
**/

.ant-btn.full-width {
  width: 100%;
  max-width: none;
  display: block;
}

.ant-btn.small {
  height: 30px;
  min-width: 0;
}

.ant-btn.medium {
  height: 35px;
}

.ant-btn.small.ant-btn-circle {
  height: 32px;
}

.ant-btn.large {
  height: 46px;
  border-radius: 6px;
}

.ant-btn.disabled-plain {
  pointer-events: none;
}

.ant-btn-default-global,
.ant-btn-default-dark-global {
  height: 40px;
  border-radius: 6px;
}

.ant-btn-default-global {
  color: #a7a7a7;
}

.ant-btn-default-dark-global {
  color: #909090;
  border-color: #909090;
}

.ant-btn-primary-global {
  background-color: #DF0E89;
  border-color: #DF0E89;
  height: 40px;
  min-width: 150px;
  border-radius: 6px;
  color: #fff;
}

.ant-btn-primary-global:hover {
  background-color: #F633AB !important;
  border-color: #F633AB !important;
  color: #fff !important;
}

.ant-btn-primary-global:active,
.ant-btn-primary-global:focus {
  background-color: #cc007B !important;
  border-color: #cc007B !important;
  color: #fff !important;
}

.ant-btn-primary-global:disabled {
  background-color: #c2c2c2 !important;
  border-color: #c2c2c2 !important;
  color: #fff !important;
}

.ant-btn-primary-global.bordered,
.ant-btn-primary-global.bordered:hover,
.ant-btn-primary-global.bordered:active,
.ant-btn-primary-global.bordered:focus {
  background-color: #fff !important;
}

.ant-btn-primary-global.bordered {
  color: #DF0E89 !important;
}
.ant-btn-primary-global.bordered:hover {
  color: #F633AB !important;
}
.ant-btn-primary-global.bordered:active {
  color: #cc007B !important;
}

.ant-btn-secondary-global {
  background-color: #4D77F8;
  border-color: #4D77F8;
  height: 40px ;
  max-width: 220px;
  min-width: 150px;
  border-radius: 6px;
  color: #fff;
}

.ant-btn-secondary-global:hover {
  background-color: #6389FB !important;
  border-color: #6389FB !important;
  color: #fff !important;
}

.ant-btn-secondary-global:active,
.ant-btn-secondary-global:focus {
  background-color: #4169E0 !important;
  border-color: #4169E0 !important;
  color: #fff !important;
}

.ant-btn-secondary-global:disabled {
  background-color: #c2c2c2 !important;
  border-color: #c2c2c2 !important;
  color: #fff !important;
}

.ant-btn-secondary-global.bordered,
.ant-btn-secondary-global.bordered:hover,
.ant-btn-secondary-global.bordered:active,
.ant-btn-secondary-global.bordered:focus {
  background-color: #fff !important;
}

.ant-btn-secondary-global.bordered {
  color: #4D77F8 !important;
}
.ant-btn-secondary-global.bordered:hover {
  color: #6389FB !important;
}
.ant-btn-secondary-global.bordered:active {
  color: #4169E0 !important;
}

/*
 * Border radius
**/

.ant-menu-submenu > .ant-menu,
.ant-pagination-item,
.ant-modal-content {
  border-radius: 2px !important;
}

div.tc-reject-button:hover {
	background-color: #DF0E89;
	color:white !important;
}

div.tc-accept-button:hover {
	color: #4D77F8;
}
div.tc-reject-button {
  color: #DF0E89 !important;
}

div.tc-accept-button {
  color:#909090;
}
div.mainModalCalling div.appointment-btn-wrapper div:first-child{
  border-right: 1px solid #E8E8E8;
}
div.mainModalCalling div.appointment-btn-wrapper-full div.tc-reject-button-text-white{
  color: white !important
}
div.mainModalCalling div.appointment-btn-wrapper div.tc-reject-button:hover{
  border-bottom-right-radius: 2px;
}
div.mainModalCalling div.appointment-btn-wrapper-full{
  border-top: 1px solid #E8E8E8;
  cursor: pointer;
}
div.mainModalCalling div.appointment-btn-wrapper-full div.tc-reject-button:hover{
  border-radius: 0px 0px 10px 10px ;
}
div.mainModalCalling div.appointment-btn-wrapper-full div.tc-reject-button{
  padding: 7px;
}
div.mainModalCalling div.appointment-btn-wrapper div{
  display: inline-block;
  width: 50%;
  border-top: 1px solid #E8E8E8;
  padding: 7px;
  cursor: pointer;
}
div.parentModalCalling .ant-modal-body{
  padding: 0px;
}


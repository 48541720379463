.ant-tabs-nav .ant-tabs-tab-active {
  color: #4d77f8 !important;
}
.ant-tabs-ink-bar {
  background-color: #4d77f8 !important;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #4d77f8 !important;
}
.ant-tabs-bar {
  margin-bottom: 0 !important;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #fbfbfb !important;
}
.ant-tabs-nav.ant-tabs-nav-animated {
  font-size: 16px;
}
.adminComponent {
  background-color: #4d77f812;
  margin: 40px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 2px -1px 17px 0px #8888886b;
  margin-top: 10px;
}
.subscriptionModal .ant-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.subscriptionModal .ant-input-number, .subscriptionModal .ant-input-number-input{
  height: 45px !important ;
}
.subscriptionModal input, .subscriptionModal .ant-select-lg > .ant-select-selection--single{
  margin-top: 0px;
}
.subscriptionModal .ant-btn.ant-btn-primary {
  background-color: #f5f5f5 !important;;
  border-color: #d9d9d9 !important;;
  color: #df0e89 !important;
  height: initial !important;
  border-radius: 4px !important;;
  height: 32px !important;
}
tbody {
  background-color: white !important;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #4d77f8 !important;
}
/* .ant-btn-danger {
  color: #df0e89 !important;
} */
.ant-btn-danger:hover {
  background-color: #df0e89 !important;
  border-color: #df0e89 !important;
  color: white !important;
}
.ant-btn:hover, .ant-btn:focus {
  color:  #4d77f8;
  border-color:  #4d77f8;
}
.ant-pagination-item-active, .ant-pagination-item:hover, .ant-pagination-item:focus {
  border-color: #4d77f8 !important;
}
.ant-pagination-item-active a {
  color: #4d77f8 !important;
}
.adminPageTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #4d77f8;
  height: 30px;
  line-height: 1.7;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.nav .sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
}

.nav .logo {
    text-align: center;
    margin: 20px 0;
}

.nav .options {
    text-align: center;
    margin-bottom: 15px;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.adminNav .ant-menu-item-selected {
  background-color: #4d77f812 !important;
  color: #4d77f8 !important;
}
.adminNav {
  width: 250px;
  position: fixed;
  height: 100vh;
  padding-top: 60px;
  box-shadow: 1px -2px 20px 0px #8888886b !important;
  background-color: #4d77f812;
}

.companyprofile{
  padding: '0px 24px 24px 24px';
}

.companyprofile .hideAnimation{
  opacity: 0;
  transition: visibility 0s, opacity 1.5s linear;
}

.tagDesign {
  height: 40px !important;
  border-radius: 27px !important;
  background-color: #4D77F8 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 0px 10px !important;
  font-family: 'NunitoSans' !important;
  margin-bottom: 24px !important;
}

div.desc-editor {
  min-height: 150px;
  /* height: 150px; */
  /* max-height: 200px; */
}

.remove-education, .add-education{
  font-size: 30px;
  margin: 13px 5px;
  color: #E0E0E0;
}

.createJobModalLabel .ant-select-selection--single{
  padding-top: 0px;
  height: 45px !important;
}

.public-DraftEditor-content {
  min-height: 150px;
}

div.desc-editor > div[class*="EditorToolbar"] {
  display: none;
}

input, select, .ant-select-lg > .ant-select-selection--single{
  height: 45px !important ;
}
.createJobModalMain input, .createJobModalMain .ant-select-lg > .ant-select-selection--single{
  margin-top: 0px;
}
.modalTagDesgin{
  margin-bottom: 5px;
}
.tabDesigText{
  display: inline-block !important;
  line-height: 21px !important;
  font-size: 16px;
  margin-top: 10px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-tag .anticon-close {
  position: relative;
  top: -6px;
}
.tagDesign > i {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
.createJobModalLabel > .ant-form-item-label > label{
  font-size: 18px;
}
.companyprofile .profileLeft{
  min-height: 300px;
  border-radius: 2px;
  top: -150px;
  position: relative;
}

.companyprofile .acceptDecline{
  background-color: white;
  margin-top: 20px;
  min-height: 40px;
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.1);
  border-radius: 2px;
  text-align: center;
}
.companyprofile .acceptDecline > div{
  width: 100%;
  display: inline-block;
  padding-top: 10px;
  color: #646464;
  font-size: 14px;
  min-height: 40px;
  cursor: pointer;
  transition: .3s all;
}

.companyprofile div.tc-accept-button {
  color:#909090;
}

.companyprofile div.tc-accept-button:hover {
	color: #4D77F8;
}

.companyprofile .profileLeft > div{
  background-color: white;
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.1);
}

.addJobModalButtons .ant-btn-default{
  color: #DF0E89;
  border-color: #DF0E89;
}
.companyprofile div.trainingcenter-details-card{
  background-color: white;
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.1);
  border-radius: 2px;
  top: -150px;
  position: relative;
  padding: 6px 15px;
  margin-bottom: 40px;
}
.companyprofile div.trainingcenter-details-card a i {
  transition: .3s all;
}
.companyprofile div.trainingcenter-details-card a:hover{
  color: black;
}
.companyprofile div.trainingcenter-details-card a:hover i {
  color: #4D77F8 !important;
}
.companyprofile div.trainingcenter-details-card a:hover img {
  filter: invert(40%) sepia(31%) saturate(2128%) hue-rotate(199deg) brightness(105%) contrast(95%);
}
.companyprofile div.trainingcenter-details-card a > div{
  margin: 10px 0px;
}
.companyprofile div.trainingcenter-details-card p {
	color: #1F2F42;
	font-family: NunitoSans;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 0.23px;
	line-height: 24px;
  margin-bottom: 0;
  display: inline;
  padding-left: 10px;
}

.companyprofile .postingjobmainblue{
  color: #4D77F8;
  font-size: 14px;
  font-weight: 900;
  text-align: center;
  padding-bottom: 10px;
}
.companyprofile .postingjobmaingrey{
  color: #909090;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
}

.companyprofile .profileRight{
  background-color: white;
  min-height: 200px;
  padding: 20px;
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.1);
  border-radius: 2px;
  top: -150px;
}

.companyprofile .profileRightMiddle{
  padding: 20px 0px;
  top: -150px;
  float: right;
}

.companyprofile .profileRightBottm{
  padding: 20px 0px;
  top: -180px;
  float: right;
}

.companyprofile .profileRightBottmJobs{
  background-color: white;
  min-height: 150px;
  padding: 20px;
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.1);
  border-radius: 2px;
  margin-bottom: 20px;
}

.companyprofile .profileRightBottmJobsAdd{
  background-color: white;
  min-height: 175px;
  padding: 20px;
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.1);
  border-radius: 2px;
  margin-bottom: 20px;
  text-align: center;
  cursor: pointer;
}

.companyprofile .profileRightBottmJobsAdd > img{
  padding-top: 5px;
  padding-bottom: 10px;
}

.companyprofile .profileLogo{
  max-width: 150px;
  max-height: 150px;
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.1);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 160px;
  width: 160px;
  margin: 0;
  background-color: white;
}

.companyprofile .profileLogoMain{
  padding: 15px;
  text-align: center;
  /* top: -25px; */
  position: relative;
}
.companyprofile .profileTitleMain{
  padding: 0px 15px 10px;
}

.companyprofile .profilebackgroundMain{
  background-repeat:   no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(0,0,0,0.1);
  height: 300px;
  left: -24px;
  width: calc(100% + 48px);
  position: relative;
}

.profileLogoParent {
  position: relative;
  height: 150px;
}

.profilebackgroundMainModal{
  background-repeat:   no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: rgba(0,0,0,0.1);
  height: 105px !important;
  left: -24px;
  width: calc(100% + 48px);
  position: absolute;
}

.profileLogoModal{
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.1);
  top: 20px !important;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 120px !important;
  width: 120px !important;
  background-color: white;
}

.profileLogoUploadButtonMain {
  position: absolute;
  top: 95px !important;
  width: 122px !important;
  margin: 0;
}

.profileLogoUploadButton{
  position: relative;
  top: 10px !important;
  width: 122px !important;
  background-color: rgba(0,0,0,0.5);
  left: -1px;
  border-radius: 0px;
  color: white;
  font-size: 10px;
  padding: 0 10px;
}
.profileBackgroundUploadButton{
  width: 122px;
  background-color: rgba(0,0,0,0.5);
  color: white;
  font-size: 10px;
  padding: 0 10px;
}

.profileBackgroundUploadButtonMainCompany{
  top: 5px;
  float: right;
  right: -20px;
  margin: 0;
  position: absolute;
}

.profileForm .ant-form-item {
  margin-bottom: 15px;
}

.profileForm label, .profileForm input {
  font-size: 16px;
}

.saveProfileModal{
  left: calc(100% - 150px);
  width: 150px !important;
}

.demo-wrapper{
  border: 1px solid #d9d9d9;
}

.companyprofile .profileEditButton{
  color: #DF0E89;
  cursor: pointer;
  padding-bottom: 20px;
  text-decoration: underline;
  transition: .3s all;
  font-size: 14px !important;
}

.companyprofile .profileEditButton:hover {
  color: #4D77F8;
}
.companyprofile .profileEditButton > a{
  color: #004F9F;
}

.companyprofile .profileTitle{
  font-size: 12px;
  font-weight: bold;
  padding-top: 15px;
}

.companyprofile .profileJobTitle{
  font-size: 18px;
  font-weight: 900;
  min-height: 50px;
  line-height: 25px;
  margin-bottom: 5px;
}
.addJobModalButtons {
  text-align: right;
}
.saveJobModal{
  display: inline-block !important;
  width: 150px !important;
  margin-left: 10px;
}

.companyprofile .ProfileJobDuration{
  height: 25px;
  width: 89px;
  border: 1px solid #DF0E89;
  border-radius: 5px;
  text-align: center;
  color: white;
  background-color: #DF0E89;
  font-size: 14px;
  font-weight: 600;
  float: right;
  padding-top: 3px;
}

.companyprofile .profileJobLogin{
  font-size: 16px;
  font-weight: bold;
}

.companyprofile .profileDesc{
  font-size: 16px;
}

.profileDesc p {
  font-family: 'NunitoSans' !important;
  /* color: #806767; */
  /* margin-bottom: 10px; */
}

.companyprofile .profileCompanyTitle{
  font-size: 22px;
  font-weight: 600;
  color: #4D77F8;
}

.companyprofile .paddingBottom{
  padding-bottom: 15px;
}

.companyprofile .profileHeadingLine{
  display: inline-block;
  width: calc(100% - 197px);
  float: right;
  top: 15px;
  position: relative;
}

.ant-modal-confirm-btns .ant-btn, .ant-modal-confirm-btns .ant-btn-primary {
  width: 150px;
  background-color: #DF0E89;
  border-color: #DF0E89;
  color: #ffffff;
  height: 45px !important;
  max-width: 220px;
  border-radius: 23px;
  margin-bottom: 24px;
  margin-right: 10px;
  float: right;
}

.ant-popover-buttons > .ant-btn-primary{
  height: 25px !important;
  min-width: 60px;
  margin-bottom: 0px !important;
}

.ant-popover-buttons > .ant-btn-sm{
  max-width: 220px !important;
  border-radius: 23px !important;
  height: 25px !important;
  min-width: 60px;
}

.companyprofile .profileHeadingTitle > hr{
  height: 4px;
  background-color: #000;
  border-color: #000;
}
.companyprofile .profileHeadingTitle{
  font-size: 40px;
  color: #000;
}

.companyprofile .profileRightSubHeading{
  font-size: 22px;
  font-weight: 900;
  color: #4D77F8;
  min-width: 200px; /*check here*/
  line-height: 36px;
}
.companyprofile .profileRightSubHeadingRight{
  float: right;
}
.companyprofile .createJobs > svg{
  height: 27px;
  width: 27px;
  top: 5px;
  position: relative;
  margin-left: 5px;
  cursor: pointer;
}

.companyprofile .jobPostedAt{
  font-size: 16px;
  font-weight: 600;
}

.companyprofile .jobPostedAt i {
  transition: .3s all;
}

.companyprofile .jobPostedAt i:hover {
	color:#4D77F8 !important;
}

.companyprofile .jobPostedAt span{
  color: #646464;
  font-weight: 400;
  line-height: 30px;
  display: inline-block;
}
.companyprofile .jobPostedAt time{
  /* color: #232323; */
}

.profileRight.followers {
  padding-bottom: 16px
}
.profileRight .ant-carousel .slick-slide {
  overflow: hidden;
  min-height: 270px;
}
.profileRight .ant-carousel .slick-prev, .profileRight .ant-carousel .slick-next {
  color: #ffffff;
  background-color: #4D77F8;
  top: 108%;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  z-index: 9;
}
.profileRight .ant-carousel .slick-prev {
  left: 5px;
  padding: 3px 2px;
}
.profileRight .ant-carousel .slick-next {
  right: 5px;
  padding: 3px 3px;
}
.profileRight .ant-carousel .slick-prev i, .profileRight .ant-carousel .slick-next i {
  color: #ffffff;
  font-size: 25px;
}
.profileRight .ant-card {
  border: 1px solid #E6E6E6;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.11);
  width: 95% !important;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}
.profileRight .ant-card-body {
  padding: 10px;
}
.profileRight .ant-row {
  margin-bottom: 10px;
}
.profileRight .ant-col-lg-20 {
  color: #909090;
  font-size: 12px;
  font-weight: 900;
  line-height: 32px;
}
.profileRight .ant-card .title {
  color: #232323;
  font-size: 12px;
  font-weight: 600;
}
.profileRight .ant-card .content {
  color: #909090;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}
.profileRight .ant-card .ant-btn {
  max-width: 100% !important;
  width: 100%;
  border-radius: 5px !important;
  font-size: 12px;
  font-weight: 900;
}
.profileRight .followCount {
  text-align: center;
  color: #909090;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  padding-top: 14px;
}

@media only screen and (min-width: 992px) {
  .companyprofile .profileRight, .profileRightMiddle, .profileRightBottm{
    margin-left: 20px;
    margin-right: calc(8.3333% - 20px);
  }
}

@media only screen and (max-width: 992px) {
  .companyprofile .profileRight, .profileRightMiddle, .profileRightBottm{
    margin-top: 20px;
  }
  .companyprofile .profileLeft, .companyprofile .profileRight{
    top: -160px;
  }
  .profileRight .ant-carousel .slick-slide {
    min-height: 315px;
  }
  .profileRight .ant-carousel .slick-prev, .profileRight .ant-carousel .slick-next {
    top: 105%;
  }
  .profileRight .followCount {
    padding-top: 8px;
  }
  .companyprofile div.trainingcenter-details-card, .jobPostedAt > div > i, .companyprofile .profileRightSubHeading > span{
    display: none;
  }
}


@media only screen and (max-width: 600px) {
  .addJobModalButtons { text-align: center; }
}


.applicationmanagement {
  margin-top: 75px;
  padding: '0px 24px 24px 24px';
}

.applicationmanagement {
  margin-top: 75px;
  padding: '0px 24px 24px 24px';
}

.applicationmanagement .jobManagementPageHeading{
  color: #232323;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
}

div.ant-collapse-header:hover {
  color: #4D77F8 !important;
}

.applicationmanagement .collapseMain{
  border-radius: 0 0 4px 4px;
  background-color: #F7F7F7;
  margin-bottom: 10px;
  overflow: hidden;
  border: 0px solid;
}

.applicationmanagement .collapseMain .ant-collapse-content{
  background-color: #ffffff !important;
}

.applicationmanagement .collapseMain .ant-collapse-content .ant-collapse-content-box{
  padding: 16px 1px 6px 2px
}

.applicationmanagement .filters{
  text-align: right;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 15px;
}

.applicationmanagement .searchResult{
  min-height: 250px;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.11);
  padding: 15px;
  margin-bottom: 10px;
}

.applicationmanagement .seniority {
  height: 30px;
  padding-top: 3px;
  margin-bottom: 10px;
}

.applicationmanagement .companyLogo{
  margin: auto;
  background-color: #ffffff;
  display: inline-block;
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.1);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 50px;
  width: 50px;
  margin: auto;
}

.applicationmanagement .companyInfoHeading{
  color: #232323;
  font-size: 14px;
}



.applicationmanagement .companyInfoMain{
  display: block;
  margin: 10px 0px;
}

.applicationmanagement .searchResultIcon{
  float: right;
  margin: 6px;
  font-size: 20px;
  color: black;
}

.applicationmanagement .jobBottomInfoMain{
  position: relative;
  bottom: 0;
}

.applicationmanagement .tabDesigText{
  display: inline-block !important;
  line-height: 21px !important;
  font-size: 16px;
  margin-top: 1px;
  overflow: hidden;
  max-width: 95%;
}

.applicationmanagement .tagDesign > i {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  top: -3px;
  position: relative;
}

.applicationmanagement .tagDesign {
  height: 28px !important;
  border-radius: 27px !important;
  background-color: #4D77F8 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 0px 10px !important;
  font-family: 'NunitoSans' !important;
  margin: 5px 0px;
  max-width: 100%;
}

.applicationmanagement .seniority {
  height: 21px;
  color: #FFFFFF;
	border-radius: 5px;
  text-align: center;
  background-color: #000000;
  display: inline-block;
  padding: 0px 15px;
  margin-right: 10px;
  margin-top: 10px;
}

.applicationmanagement .tabDesigText{
  display: inline-block !important;
  line-height: 21px !important;
  font-size: 16px;
  margin-top: 1px;
  overflow: hidden;
  max-width: 95%;
}

.applicationmanagement .tagDesign > i {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  top: -3px;
  position: relative;
}

.applicationmanagement .tagDesign {
  height: 28px !important;
  border-radius: 27px !important;
  background-color: #4D77F8 !important;
  color: #fff !important;
  font-size: 16px !important;
  padding: 0px 10px !important;
  font-family: 'NunitoSans' !important;
  margin: 5px 0px;
  max-width: 100%;
}

.applicationmanagement .searchHeading{
  display: inline-block;
  position: absolute;
  min-width: fit-content;
  margin: 0px 15px;
  color: #232323;
  font-size: 18px;
  font-weight: 600;
}


.applicationmanagement .companyInfo{
  display: inline-block;
  margin: 0px 15px;
  color: #909090;
  font-size: 14px;
}

.applicationmanagement .companyFollow{
  position: absolute;
  bottom: 10px;
  width: calc(100% - 10px);
  height: 40px;
  text-align: center;
  border-top: 1px solid #D8D8D8;
  left: 5px;
  cursor: pointer;
}

.applicationmanagement .companyFollow button {
  transition: .3s all;
}
.applicationmanagement .companyFollow button:before {
  display: none !important;
}

.applicationmanagement .companyFollow:hover button span,
.applicationmanagement .companyFollow:hover button i.anticon-star {
  display: none;
}
.applicationmanagement .companyFollow:hover i.anticon-loading {
  margin-right: 14px;
  color: #4D77F8;
}
.applicationmanagement .companyFollow:hover button:after {
  content: 'Unfollow';
  color: #4D77F8 !important;
  display: inline-block;
  margin-top: 1px;
  position: relative;
  opacity: 1;
  border: none !important;
  line-height: 39px !important;
  height: 39px !important;
}

.applicationmanagement .companyFollow div{
  margin-top: 10px;
  height: 40px;
  color: #909090;
  font-size: 14px;
  cursor: pointer;
  transition: .3s all;
}

.applicationmanagement .jobAppsearchResult{
  min-height: 300px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 1px 0 #B5B5B5, 0 2px 1px 0 #E2E1E1, 0 1px 4px 1px rgba(0,0,0,0.11);
  margin-bottom: 16px;
  padding: 15px;
  position: relative;
  border-radius: 6px;
}

.applicationmanagement .jobAppsearchHeading{
  color: #232323;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  display: block;
  top: -9px;
}

.applicationmanagement .jobAppseniority {
  height: 30px;
  color: #FFFFFF;
	border-radius: 5px;
  text-align: center;
  background-color: #4D77F8;
  display: inline-block;
  padding: 5px 15px;
  margin-right: 10px;
  margin-top: 10px;
  font-weight: 900;
}

.applicationmanagement .jobAppjobBottomInfoMain{
  position: absolute;
  bottom: 0;
  width: calc(100% - 31px);
}

.applicationmanagement .jobAppjobBottomInfoMain hr{
  width: calc(100% + 32px);
  left: -16px;
  position: relative;
  margin: 0px;
  border: 0;
  border-top: 1px solid #D8D8D8;
}

.applicationmanagement .jobAppcompanyLogo{
  height: 34px;
  width: 34px;
  margin: auto;
  background-color: #B7B7B7;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.applicationmanagement .jobAppcompanyInfoMain{
  display: inline-block;
  margin: 10px 10px;
  max-width: calc(100% - 55px);
}

.applicationmanagement .jobAppcompanyInfo{
  color: #909090;
  font-size: 14px;
  margin: 0px;
}

.applicationmanagement .jobAppjobPostedAt{
  color: #909090;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.applicationmanagement .jobAppsearchAccRejButton{
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 3px 10px;
  color: #909090;
  cursor: pointer;
  font-weight: 900;
  transition: .3s all;
}

.applicationmanagement .jobAppsearchAccRejButton:hover button span,
.applicationmanagement .jobAppsearchAccRejButton:hover button i.anticon-heart {
  display: none;
}

.applicationmanagement .jobAppsearchAccRejButton:hover i.anticon-loading {
  margin-right: 14px;
  color: #4D77F8;
}
.applicationmanagement .textChangeApplied:hover button:after{
  content: 'Cancel application';
}
.applicationmanagement .textChangeChat:hover button:after{
  content: 'Chat';
}
.applicationmanagement .jobAppsearchAccRejButton:hover button:after {
  color: #4D77F8 !important;
  display: inline-block;
  opacity: 1;
  border: none !important;
  position: relative;
}

.applicationmanagement .jobAppsearchAccRejButton .ant-col div{
  width: 1px;
  border-right: 1px solid #D8D8D8;
  height: 38px;
  display: -webkit-inline-box;
  position: absolute;
  right: 0px;
  top:0px;
}

.applicationmanagement .jobAppsearchAccRejButton .ant-col:nth-child(2):hover {
	background-color: #DF0E89;
  color:white !important;
  border-radius: 0px 0px 6px 0px;
}

.applicationmanagement .jobAppsearchAccRejButton .ant-col:first-child:hover {
	color: #4D77F8;
}

.applicationmanagement .jobAppsearchAccRejButton .ant-col:first-child{
  color:#909090;
  font-weight: 400;
  position: absolute;
  left: 0;
  height: 38px;
  padding-top: 8px;
}

.applicationmanagement .jobAppsearchAccRejButton .ant-col:nth-child(2){
  color: #DF0E89;
  font-weight: 400;
  position: absolute;
  right: 0;
  height: 38px;
  padding-top: 9px;
  margin-right: -16px;
  border-radius: 0 0 2px 0;
  width: calc(50% + 16px);
}

.applicationmanagement .jobAppsearchAccRejButton .ant-col {
  transition: .3s all;
}

.applicationmanagement .ant-collapse-header{
  font-weight: 900;
}

.applicationmanagement .hideAnimation{
  opacity: 0;
  transition: visibility 0s, opacity 1.5s linear;
}

@media screen and (max-device-width: 768px) {
  .applicationmanagement {
    margin-top: 105px;
    padding: '0px 24px 24px 24px';
  }
}

